import { HERO_AND_DATA_PROGRAM_OPTIONS } from "./consts";

const outstandingPrincipalReport = {
  label: "Outstanding Principal Report",
  name: "outstandingPrincipalReport",
  parameters: [
    {
      name: "bondcall",
      label: "BondCall",
      type: "date",
    },
    {
      name: "program",
      label: "Program",
      type: "select",
      defaultValue: "hero_select",
      options: HERO_AND_DATA_PROGRAM_OPTIONS,
    },
    {
      name: "propertyType",
      label: "Property Type",
      type: "select",
      defaultValue: "Residential",
      options: [
        { key: "Residential", label: "Residential" },
        { key: "Commercial", label: "Commercial" },
      ],
    },
    {
      name: "taxYear",
      label: "Tax year",
      type: "year",
    },
  ],
};

export default outstandingPrincipalReport;
