import { HERO_AND_DATA_PROGRAM_OPTIONS } from "./consts";

export const bondCallSummaryReport = {
  label: "Bond Call Summary Report",
  name: "bondCallSummaryReport",
  parameters: [
    {
      name: "bond_call",
      label: "Bond Call Date",
      type: "date",
    },
    {
      name: "agency",
      label: "Agency",
      type: "select",
      defaultValue: "SANBAG",
      options: [
        { key: "SANBAG", label: "SANBAG" },
        { key: "LA HERO", label: "LA HERO" },
        { key: "WRCOG", label: "WRCOG" },
        { key: "MCED", label: "MCED" },
        { key: "CSCDA", label: "CSCDA" },
        { key: "AN", label: "AN" },
        { key: "CF", label: "CF" },
        { key: "CF - LA", label: "CF - LA" },
        { key: "CFW", label: "CFW" },
        { key: "PF", label: "PF" },
        { key: "PFW", label: "PFW" },
        { key: "PFLA", label: "PFLA" },
        { key: "PFG FL", label: "PFG FL" },
        { key: "PFMO", label: "PFMO" },
        { key: "E3CA", label: "E3CA" },
        { key: "E3FL", label: "FF" },
        { key: "CESFL", label: "CESFL" },
        { key: "LEIFL", label: "Leidos" },
      ],
    },
  ],
};

export const bondCallSummaryReportNew = {
  label: "Bond Call Summary Report - NEW",
  name: "bondCallSummaryReportNew",
  parameters: [
    {
      name: "bond_call",
      label: "Bond Call Date",
      type: "date",
    },
    {
      name: "program",
      label: "Program",
      type: "select",
      defaultValue: "hero_select",
      options: HERO_AND_DATA_PROGRAM_OPTIONS,
    },
    {
      name: "separated_by_tranche",
      label: "Separated by Tranche/Indenture",
      type: "select",
      defaultValue: "no",
      options: [
        { key: "no", label: "No" },
        { key: "yes", label: "Yes" },
      ],
    },
    {
      name: "trustee",
      label: "Trustee (HERO Programs)",
      type: "select",
      defaultValue: "all",
      options: [
        { key: "all", label: "All" },
        { key: "Bank of New York Mellon", label: "Bank of New York Mellon" },
        { key: "Deutsche Bank", label: "Deutsche Bank" },
        { key: "Wilmington Trust", label: "Wilmington Trust" },
      ],
    },
  ],
};
