const HERO_PROGRAM_OPTIONS = [
  { key: "CBB", label: "CBB" },
  { key: "CLN", label: "CLN" },
  { key: "CSCDA", label: "CSCDA" },
  { key: "GW", label: "GW" },
  { key: "GWC", label: "GWC" },
  { key: "GWCEDB", label: "GWCEDB" },
  { key: "GWM", label: "GWM" },
  { key: "LA HERO", label: "LA HERO" },
  { key: "LVR", label: "LVR" },
  { key: "MCED", label: "MCED" },
  { key: "OP", label: "OP" },
  { key: "PF_HERO", label: "PF" },
  { key: "PLG", label: "PLG" },
  { key: "PP-CMFA", label: "PP-CMFA" },
  { key: "PP-CSCDA", label: "PP-CSCDA" },
  { key: "SANBAG", label: "SANBAG" },
  { key: "SH", label: "SH" },
  { key: "SMS", label: "SMS" },
  { key: "TW", label: "TW" },
  { key: "TWC", label: "TWC" },
  { key: "WAB", label: "WAB" },
  { key: "WO", label: "WO" },
  { key: "WRCOG", label: "WRCOG" },
];

export const DATA_PROGRAM_OPTIONS = [
  { key: "AN", label: "AN" },
  { key: "BVC", label: "BVC" },
  { key: "BVF", label: "BVF" },
  { key: "CCF", label: "CCF" },
  { key: "CESFL", label: "CESFL" },
  { key: "CF", label: "CF" },
  { key: "CF - LA", label: "CF - LA" },
  { key: "CFW", label: "CFW" },
  { key: "CG", label: "CG" },
  { key: "E3CA", label: "E3CA" },
  { key: "E3FL", label: "E3FL" },
  { key: "FB-TN", label: "FB-TN" },
  { key: "FB-WA", label: "FB-WA" },
  { key: "FBC", label: "FBC" },
  { key: "FBG", label: "FBG" },
  { key: "FBGC", label: "FBGC" },
  { key: "GPC", label: "GPC" },
  { key: "GPCG", label: "GPCG" },
  { key: "GRC", label: "GRC" },
  { key: "LACEP", label: "LACEP" },
  { key: "LEIFL", label: "LEIFL" },
  { key: "NBC", label: "NBC" },
  { key: "NBF", label: "NBF" },
  { key: "PB", label: "PB" },
  { key: "PF_DATA", label: "PF" },
  { key: "PFG FL", label: "PFG FL" },
  { key: "PFG MCED", label: "PFG MCED" },
  { key: "PFLA", label: "PFLA" },
  { key: "PFMO", label: "PFMO" },
  { key: "PFW", label: "PFW" },
  { key: "RW", label: "RW" },
  { key: "SFC", label: "SFC" },
];

export const HERO_AND_DATA_PROGRAM_OPTIONS = [
  { key: "hero_select", label: "-- HERO Programs --" },
  ...HERO_PROGRAM_OPTIONS,
  { key: "data_select", label: "-- DATA Programs --" },
  ...DATA_PROGRAM_OPTIONS,
];
