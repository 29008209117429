import { DATA_PROGRAM_OPTIONS } from "./consts";

const paidQuoteReport = {
  label: "Paid Quote Report",
  name: "paidQuoteReport",
  parameters: [
    {
      name: "agency",
      label: "Agency",
      type: "select",
      defaultValue: "ALL",
      options: [{ key: "ALL", label: "ALL" }, ...DATA_PROGRAM_OPTIONS],
    },
  ],
};

export default paidQuoteReport;
