const fpfaRevisedOspReport = {
  label: "FPFA Revised OSP Report",
  name: "fpfaRevisedOspReport",
  parameters: [
    {
      name: "bondCall",
      label: "Bond Call Date",
      type: "date",
    },
  ],
};

export default fpfaRevisedOspReport;
