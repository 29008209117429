import { HERO_AND_DATA_PROGRAM_OPTIONS } from "./consts";

const poInfoReport = {
  label: "PO Info Report",
  name: "poInfoReport",
  parameters: [
    {
      name: "program",
      label: "Program",
      type: "select",
      defaultValue: "hero_select",
      options: HERO_AND_DATA_PROGRAM_OPTIONS,
    },
  ],
};

export default poInfoReport;
