import { HERO_AND_DATA_PROGRAM_OPTIONS } from "./consts";

const payoffQuoteCounterReport = {
  name: "payoffQuoteCounterReport",
  label: "Payoff Quote Counter Report",
  parameters: [
    {
      name: "startDate",
      label: "Start date",
      type: "date",
    },
    {
      name: "endDate",
      label: "End date",
      type: "date",
    },
    {
      name: "program",
      label: "Program",
      type: "select",
      defaultValue: "hero_select",
      options: HERO_AND_DATA_PROGRAM_OPTIONS,
    },
  ],
};

export default payoffQuoteCounterReport;
